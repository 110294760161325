import React from "react"
import Racepage from "../components/racepage"

const Vuelta2020 = () => {
  const startlist = [
    {
      firstname: " Ricardo",
      lastname: " VILELA",
      number: " 218",
      team: "Burgos-BH"
    },
    {
      firstname: " Willie",
      lastname: " SMIT",
      number: " 217",
      team: "Burgos-BH"
    },
    {
      firstname: " Juan Felipe",
      lastname: " OSORIO",
      number: " 216",
      team: "Burgos-BH"
    },
    {
      firstname: " Alex",
      lastname: " MOLENAAR",
      number: " 215",
      team: "Burgos-BH"
    },
    {
      firstname: " Jesús",
      lastname: " EZQUERRA",
      number: " 214",
      team: "Burgos-BH"
    },
    {
      firstname: " Óscar",
      lastname: " CABEDO",
      number: " 213",
      team: "Burgos-BH"
    },
    {
      firstname: " Jetse",
      lastname: " BOL",
      number: " 212",
      team: "Burgos-BH"
    },
    {
      firstname: " Ángel",
      lastname: " MADRAZO",
      number: " 211",
      team: "Burgos-BH"
    },
    {
      firstname: " Gonzalo",
      lastname: " SERRANO",
      number: " 208",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Héctor",
      lastname: " SÁEZ",
      number: " 207",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Jhojan",
      lastname: " GARCÍA",
      number: " 206",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Jefferson Alveiro",
      lastname: " CEPEDA",
      number: " 205",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Aritz",
      lastname: " BAGÜES",
      number: " 204",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Julen",
      lastname: " AMEZQUETA",
      number: " 203",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Jon",
      lastname: " ABERASTURI",
      number: " 202",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Jonathan",
      lastname: " LASTRA",
      number: " 201",
      team: "Caja Rural - Seguros RGA"
    },
    {
      firstname: " Julien",
      lastname: " SIMON",
      number: " 198",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Romain",
      lastname: " SICARD",
      number: " 197",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Paul",
      lastname: " OURSELIN",
      number: " 196",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Pim",
      lastname: " LIGTHART",
      number: " 195",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Jonathan",
      lastname: " HIVERT",
      number: " 194",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Valentin",
      lastname: " FERRON",
      number: " 193",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Lorrenzo",
      lastname: " MANZIN",
      number: " 192",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Niki",
      lastname: " TERPSTRA",
      number: " 191",
      team: "Team Total Direct Energie"
    },
    {
      firstname: " Matteo",
      lastname: " BADILATTI",
      number: " 188",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Rory",
      lastname: " SUTHERLAND",
      number: " 187",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Alexis",
      lastname: " RENARD",
      number: " 186",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Mihkel",
      lastname: " RÄIM",
      number: " 185",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " James",
      lastname: " PICCOLI",
      number: " 184",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Reto",
      lastname: " HOLLENSTEIN",
      number: " 183",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Omer",
      lastname: " GOLDSTEIN",
      number: " 182",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Dan",
      lastname: " MARTIN",
      number: " 181",
      team: "Israel Start-Up Nation"
    },
    {
      firstname: " Carlos",
      lastname: " VERONA",
      number: " 178",
      team: "Movistar Team"
    },
    {
      firstname: " Marc",
      lastname: " SOLER",
      number: " 177",
      team: "Movistar Team"
    },
    {
      firstname: " José Joaquín",
      lastname: " ROJAS",
      number: " 176",
      team: "Movistar Team"
    },
    {
      firstname: " Nelson",
      lastname: " OLIVEIRA",
      number: " 175",
      team: "Movistar Team"
    },
    {
      firstname: " Enric",
      lastname: " MAS",
      number: " 174",
      team: "Movistar Team"
    },
    {
      firstname: " Imanol",
      lastname: " ERVITI",
      number: " 173",
      team: "Movistar Team"
    },
    {
      firstname: " Jorge",
      lastname: " ARCAS",
      number: " 172",
      team: "Movistar Team"
    },
    {
      firstname: " Alejandro",
      lastname: " VALVERDE",
      number: " 171",
      team: "Movistar Team"
    },
    {
      firstname: " Gino",
      lastname: " MÄDER",
      number: " 168",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Reinardt",
      lastname: " JANSE VAN RENSBURG",
      number: " 167",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Michael",
      lastname: " VALGREN",
      number: " 166",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Enrico",
      lastname: " GASPAROTTO",
      number: " 165",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Benjamin",
      lastname: " DYBALL",
      number: " 164",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Nic",
      lastname: " DLAMINI",
      number: " 163",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Stefan",
      lastname: " DE BOD",
      number: " 162",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Carlos",
      lastname: " BARBERO",
      number: " 161",
      team: "NTT Pro Cycling"
    },
    {
      firstname: " Pierre-Luc",
      lastname: " PÉRICHON",
      number: " 158",
      team: "Cofidis"
    },
    {
      firstname: " Emmanuel",
      lastname: " MORIN",
      number: " 157",
      team: "Cofidis"
    },
    {
      firstname: " Luis Ángel",
      lastname: " MATÉ",
      number: " 156",
      team: "Cofidis"
    },
    {
      firstname: " Victor",
      lastname: " LAFAY",
      number: " 155",
      team: "Cofidis"
    },
    {
      firstname: " José",
      lastname: " HERRADA",
      number: " 154",
      team: "Cofidis"
    },
    {
      firstname: " Natnael",
      lastname: " BERHANE",
      number: " 153",
      team: "Cofidis"
    },
    {
      firstname: " Fernando",
      lastname: " BARCELÓ",
      number: " 152",
      team: "Cofidis"
    },
    {
      firstname: " Guillaume",
      lastname: " MARTIN",
      number: " 151",
      team: "Cofidis"
    },
    {
      firstname: " Brent",
      lastname: " VAN MOER",
      number: " 148",
      team: "Lotto Soudal"
    },
    {
      firstname: " Tosh",
      lastname: " VAN DER SANDE",
      number: " 147",
      team: "Lotto Soudal"
    },
    {
      firstname: " Rémy",
      lastname: " MERTZ",
      number: " 146",
      team: "Lotto Soudal"
    },
    {
      firstname: " Tomasz",
      lastname: " MARCZYŃSKI",
      number: " 145",
      team: "Lotto Soudal"
    },
    {
      firstname: " Kobe",
      lastname: " GOOSSENS",
      number: " 144",
      team: "Lotto Soudal"
    },
    {
      firstname: " Stan",
      lastname: " DEWULF",
      number: " 143",
      team: "Lotto Soudal"
    },
    {
      firstname: " Gerben",
      lastname: " THIJSSEN",
      number: " 142",
      team: "Lotto Soudal"
    },
    {
      firstname: " Tim",
      lastname: " WELLENS",
      number: " 141",
      team: "Lotto Soudal"
    },
    {
      firstname: " Francisco José",
      lastname: " VENTOSO",
      number: " 138",
      team: "CCC Team"
    },
    {
      firstname: " Georg",
      lastname: " ZIMMERMANN",
      number: " 137",
      team: "CCC Team"
    },
    {
      firstname: " Łukasz",
      lastname: " WIŚNIOWSKI",
      number: " 136",
      team: "CCC Team"
    },
    {
      firstname: " Michał",
      lastname: " PALUTA",
      number: " 135",
      team: "CCC Team"
    },
    {
      firstname: " Jakub",
      lastname: " MARECZKO",
      number: " 134",
      team: "CCC Team"
    },
    {
      firstname: " Jan",
      lastname: " HIRT",
      number: " 133",
      team: "CCC Team"
    },
    {
      firstname: " Will",
      lastname: " BARTA",
      number: " 132",
      team: "CCC Team"
    },
    {
      firstname: " Simon",
      lastname: " GESCHKE",
      number: " 131",
      team: "CCC Team"
    },
    {
      firstname: " Axel",
      lastname: " DOMONT",
      number: " 128",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Quentin",
      lastname: " JAUREGUI",
      number: " 127",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Harry",
      lastname: " TANFIELD",
      number: " 126",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Nans",
      lastname: " PETERS",
      number: " 125",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Dorian",
      lastname: " GODON",
      number: " 124",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Alexandre",
      lastname: " GENIEZ",
      number: " 123",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Mathias",
      lastname: " FRANK",
      number: " 122",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Clément",
      lastname: " CHAMPOUSSIN",
      number: " 121",
      team: "AG2R La Mondiale"
    },
    {
      firstname: " Stephen",
      lastname: " WILLIAMS",
      number: " 118",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Alfred",
      lastname: " WRIGHT",
      number: " 117",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Matej",
      lastname: " MOHORIČ",
      number: " 116",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Kevin",
      lastname: " INKELAAR",
      number: " 115",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Scott",
      lastname: " DAVIES",
      number: " 114",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Santiago",
      lastname: " BUITRAGO",
      number: " 113",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Grega",
      lastname: " BOLE",
      number: " 112",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Wout",
      lastname: " POELS",
      number: " 111",
      team: "Bahrain - McLaren"
    },
    {
      firstname: " Michael",
      lastname: " WOODS",
      number: " 108",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Tejay",
      lastname: " VAN GARDEREN",
      number: " 107",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Julius",
      lastname: " VAN DEN BERG",
      number: " 106",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Logan",
      lastname: " OWEN",
      number: " 105",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Magnus",
      lastname: " CORT",
      number: " 104",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Mitchell",
      lastname: " DOCKER",
      number: " 103",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Hugh",
      lastname: " CARTHY",
      number: " 102",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Daniel Felipe",
      lastname: " MARTÍNEZ",
      number: " 101",
      team: "EF Pro Cycling"
    },
    {
      firstname: " Romain",
      lastname: " SEIGLE",
      number: " 98",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Anthony",
      lastname: " ROUX",
      number: " 97",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Olivier",
      lastname: " LE GAC",
      number: " 96",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Matthieu",
      lastname: " LADAGNOUS",
      number: " 95",
      team: "Groupama - FDJ"
    },
    {
      firstname: " David",
      lastname: " GAUDU",
      number: " 94",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Mickaël",
      lastname: " DELAGE",
      number: " 93",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Bruno",
      lastname: " ARMIRAIL",
      number: " 92",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Thibaut",
      lastname: " PINOT",
      number: " 91",
      team: "Groupama - FDJ"
    },
    {
      firstname: " Alex",
      lastname: " EDMONDSON",
      number: " 88",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Robert",
      lastname: " STANNARD",
      number: " 87",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Dion",
      lastname: " SMITH",
      number: " 86",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Callum",
      lastname: " SCOTSON",
      number: " 85",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Nick",
      lastname: " SCHULTZ",
      number: " 84",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Mikel",
      lastname: " NIEVE",
      number: " 83",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Tsgabu",
      lastname: " GRMAY",
      number: " 82",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Esteban",
      lastname: " CHAVES",
      number: " 81",
      team: "Mitchelton-Scott"
    },
    {
      firstname: " Cameron",
      lastname: " WURF",
      number: " 78",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Dylan",
      lastname: " VAN BAARLE",
      number: " 77",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Iván Ramiro",
      lastname: " SOSA",
      number: " 76",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Brandon Smith",
      lastname: " RIVERA",
      number: " 75",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Michał",
      lastname: " GOŁAŚ",
      number: " 74",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Andrey",
      lastname: " AMADOR",
      number: " 73",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Richard",
      lastname: " CARAPAZ",
      number: " 72",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Chris",
      lastname: " FROOME",
      number: " 71",
      team: "INEOS Grenadiers"
    },
    {
      firstname: " Rüdiger",
      lastname: " SELIG",
      number: " 68",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Michael",
      lastname: " SCHWARZMANN",
      number: " 67",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Andreas",
      lastname: " SCHILLINGER",
      number: " 66",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Ide",
      lastname: " SCHELLING",
      number: " 65",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Jay",
      lastname: " MCCARTHY",
      number: " 64",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Felix",
      lastname: " GROßSCHARTNER",
      number: " 63",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Martin",
      lastname: " LAAS",
      number: " 62",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Pascal",
      lastname: " ACKERMANN",
      number: " 61",
      team: "BORA - hansgrohe"
    },
    {
      firstname: " Luis León",
      lastname: " SÁNCHEZ",
      number: " 58",
      team: "Astana Pro Team"
    },
    {
      firstname: " Gorka",
      lastname: " IZAGIRRE",
      number: " 57",
      team: "Astana Pro Team"
    },
    {
      firstname: " Merhawi",
      lastname: " KUDUS",
      number: " 56",
      team: "Astana Pro Team"
    },
    {
      firstname: " Ion",
      lastname: " IZAGIRRE",
      number: " 55",
      team: "Astana Pro Team"
    },
    {
      firstname: " Omar",
      lastname: " FRAILE",
      number: " 54",
      team: "Astana Pro Team"
    },
    {
      firstname: " Dmitriy",
      lastname: " GRUZDEV",
      number: " 53",
      team: "Astana Pro Team"
    },
    {
      firstname: " Alex",
      lastname: " ARANBURU",
      number: " 52",
      team: "Astana Pro Team"
    },
    {
      firstname: " Aleksandr",
      lastname: " VLASOV",
      number: " 51",
      team: "Astana Pro Team"
    },
    {
      firstname: " Jasha",
      lastname: " SÜTTERLIN",
      number: " 48",
      team: "Team Sunweb"
    },
    {
      firstname: " Ilan",
      lastname: " VAN WILDER",
      number: " 47",
      team: "Team Sunweb"
    },
    {
      firstname: " Michael",
      lastname: " STORER",
      number: " 46",
      team: "Team Sunweb"
    },
    {
      firstname: " Martin",
      lastname: " SALMON",
      number: " 45",
      team: "Team Sunweb"
    },
    {
      firstname: " Max",
      lastname: " KANTER",
      number: " 44",
      team: "Team Sunweb"
    },
    {
      firstname: " Mark",
      lastname: " DONOVAN",
      number: " 43",
      team: "Team Sunweb"
    },
    {
      firstname: " Thymen",
      lastname: " ARENSMAN",
      number: " 42",
      team: "Team Sunweb"
    },
    {
      firstname: " Robert",
      lastname: " POWER",
      number: " 41",
      team: "Team Sunweb"
    },
    {
      firstname: " Michel",
      lastname: " RIES",
      number: " 38",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Emīls",
      lastname: " LIEPIŅŠ",
      number: " 37",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Alexander",
      lastname: " KAMP",
      number: " 36",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Kenny",
      lastname: " ELISSONDE",
      number: " 35",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Niklas",
      lastname: " EG",
      number: " 34",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Koen",
      lastname: " DE KORT",
      number: " 33",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Matteo",
      lastname: " MOSCHETTI",
      number: " 32",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Juan Pedro",
      lastname: " LÓPEZ",
      number: " 31",
      team: "Trek - Segafredo"
    },
    {
      firstname: " Rui",
      lastname: " OLIVEIRA",
      number: " 28",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Ivo",
      lastname: " OLIVEIRA",
      number: " 27",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Alexandr",
      lastname: " RIABUSHENKO",
      number: " 26",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Jasper",
      lastname: " PHILIPSEN",
      number: " 25",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Sergio",
      lastname: " HENAO",
      number: " 24",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Davide",
      lastname: " FORMOLO",
      number: " 23",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Rui",
      lastname: " COSTA",
      number: " 22",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " David",
      lastname: " DE LA CRUZ",
      number: " 21",
      team: "UAE-Team Emirates"
    },
    {
      firstname: " Rémi",
      lastname: " CAVAGNA",
      number: " 18",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Zdeněk",
      lastname: " ŠTYBAR",
      number: " 17",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Jannik",
      lastname: " STEIMLE",
      number: " 16",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Michael",
      lastname: " MØRKØV",
      number: " 15",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Ian",
      lastname: " GARRISON",
      number: " 14",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Mattia",
      lastname: " CATTANEO",
      number: " 13",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Andrea",
      lastname: " BAGIOLI",
      number: " 12",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Sam",
      lastname: " BENNETT",
      number: " 11",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Jonas",
      lastname: " VINGEGAARD",
      number: " 8",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " Paul",
      lastname: " MARTENS",
      number: " 7",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " Sepp",
      lastname: " KUSS",
      number: " 6",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " Lennard",
      lastname: " HOFSTEDE",
      number: " 5",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " Robert",
      lastname: " GESINK",
      number: " 4",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " Tom",
      lastname: " DUMOULIN",
      number: " 3",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " George",
      lastname: " BENNETT",
      number: " 2",
      team: "Team Jumbo-Visma",
    },
    {
      firstname: " Primož",
      lastname: " ROGLIČ",
      number: " 1",
      team: "Team Jumbo-Visma",
    },
  ]

  const race = "Vuelta"

  return (
    <Racepage title="Vuelta a España 2020" startlist={startlist} race={race} />
  )
}

export default Vuelta2020
